.Outer {
  position: fixed;
  left: calc(50% - 240px);
  bottom: 57px;
  width: 480px;
  z-index: var(--z-index-cookies);
  padding: 17px 24px;
  text-align: center;
  color: white;
  background: rgba(23, 25, 28, 0.95);
  box-shadow: var(--box-shadow-06);
  border-radius: var(--border-radius-big);
}
.CookieLink {
  color: white;
  cursor: pointer;
  padding: 4px 0;
  text-decoration: underline;
}
.CookieLink:hover {
  color: var(--text-btn-blue-light);
}
.CookieLink.Active {
  text-decoration: underline;
}

@media screen and (max-width: 900px) {
  .Outer {
    position: fixed;
    left: 16px;
    bottom: 32px;
    width: calc(100vw - 32px);
    border-radius: 12px;
  }
}
