.Faq {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  transition: all 0.24s;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

@media only screen and (max-width: 900px) {
  .CardOuter {
    /* for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* for Chrome, Safari and Opera */
  .CardOuter::-webkit-scrollbar {
    display: none;
  }
}
