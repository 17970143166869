.UserDetail {
  font-size: var(--fs-3);
  padding: 0 0 24px 0;
}
.PagesList {
  width: 100%;
  list-style-type: none;
  text-decoration: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
  margin-top: 20px;
}
.PagesList > li {
  border: var(--border-discret);
  border-top: none;
  cursor: pointer;
  color: var(--text-2);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 4px 0;
}
.PagesList > li:last-child {
  border-bottom-left-radius: var(--border-radius-big);
  border-bottom-right-radius: var(--border-radius-big);
}

.PagesList > li:first-child {
  border: var(--border-discret);
  border-top-left-radius: var(--border-radius-big);
  border-top-right-radius: var(--border-radius-big);
  background-color: var(--background-color-0);
  display: block;
  text-align: center;
}
.PagesList > li:nth-child(odd) {
  background-color: var(--background-color-0);
}

.PagesList > li:hover {
  background-color: var(--background-orange);
  border-left-color: var(--text-link-hover);
  border-right-color: var(--text-link-hover);
  color: var(--text-link);
}
.PagesList > li:first-child:hover {
  border-color: var(--text-link-hover);
}
.PagesList > li:first-child > p {
  color: var(--text-link);
}
.PagesList.SameItemStyle > li:first-child {
  border: var(--border-discret);
  border-top-left-radius: var(--border-radius-big);
  border-top-right-radius: var(--border-radius-big);
  background-color: var(--background-color-0);
  display: grid;
  text-align: unset;
}
.PagesList.SameItemStyle > li:first-child:hover {
  background-color: var(--background-orange);
  border-left-color: var(--text-link-hover);
  border-right-color: var(--text-link-hover);
  color: var(--text-link);
}
.PageListElementDragging {
  display: flex;
  border-radius: var(--border-radius-big);
}
.PageListElement {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.SettingsGroupRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.SettingsLabelInputSameRow {
  display: grid;
  grid-template-columns: 120px auto;
  align-items: baseline;
  gap: 20px;
  padding-bottom: 10px;
}
.PagesPageTitleDetailsOuter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
}
.PageSelectLanguage {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-content: flex-start;
  justify-content: flex-start;
}
.LogoMask {
  border: var(--border-discret);
  border-radius: var(--border-radius);
  width: 200px;
}

.InputUploadImage {
  display: none;
}
.SmallSubstituteForFileInput {
  width: calc(50% - 8px);
  height: 78px;
  cursor: pointer;
  padding: 16px 10px;
  background-image: url("../../assets/svg/drop_icon.svg");
  background-position: 11px center;
  background-size: 47px 47px;
  background-repeat: no-repeat;
  border: 1.5px dashed var(--border-2);
  border-radius: var(--border-radius-big);
  background-color: var(--background-color-1);
  transition: all 0.1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--text-1);
  opacity: 0.8;
}
.SmallSubstituteForFileInput:hover {
  color: var(--text-2);
  border-color: var(--text-link-hover);
  background-color: white;
  opacity: 1;
}
.PageContentOuter {
  padding: 0;
  margin: 10px 0 14px 0;
  list-style-type: none;
}
.PageContentOuter > li {
  display: flex;
  flex-direction: row;
  /* flex-wrap: nowrap; */
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  border: 1px solid var(--background-color-3);
  border-radius: var(--border-radius);
  margin: 4px 0 0 0;
  transition: 250ms all;
  overflow: hidden;
  width: 100%;
}
.PageContentComponentDetails {
  padding: 14px 10px;
}
.PageContentComponentDetailsWithBottom {
  padding: 14px 10px;
}
.PageComponentListElementMask {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--text-2);
  padding: 6px 10px 6px 20px;
  background-color: var(--background-color-0);
}
.PageComponentListElementMask:hover {
  color: var(--text-link);
  background-color: var(--background-color-2);
}
.IconRemoveElement {
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  padding: 0 24px;
  color: var(--text-link);
  background-color: white;
  background-image: url("../../assets/svg/trash_can.svg");
  background-position: center center;
  background-size: auto 70%;
  background-repeat: no-repeat;
  height: 34px;
  opacity: 0.5;
}
.IconRemoveElement:hover {
  border-color: var(--text-error);
  color: var(--text-error);
  opacity: 1;
  cursor: pointer;
}
ul > .UlSearchLi {
  padding: 12px 3px;
  background-color: transparent !important;
  border-left-style: dashed !important;
  border-right-style: dashed !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}
/* to here */
.TemplateColumnsOuter {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 14px;
}
.LabelElementDetails {
  font-size: var(--fs-3);
  padding: 4px 0;
  font-weight: 500;
}

.PageSectionsOuter {
  width: 100%;
}
.PageSection {
  padding: 10px 0;
  margin: 4px 0;
}
.PageSectionContentOuter {
  display: grid;
  grid-template-columns: 270px 1fr 270px;
  gap: 16px;
}
.PageSectionContent {
  background-color: var(--background-color-2);
}
.SelectLayoutSectionOuter {
  background-color: white;
  border-radius: var(--border-radius);
}
.SelectLayoutSectionOuter > div > div {
  z-index: var(--z-index-select);
}
.SelectLayoutSectionOuter > div > div {
  /* overwrite the select inside: selected value element */
  background-color: transparent;
  text-align: center;
}
/* THEMES */
.ThemesList {
  width: 100%;
  list-style-type: none;
  text-decoration: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
  margin-bottom: 17px;
}
.ThemesList li {
  margin: 0 0 34px 0;
  border: 2px solid var(--background-color-1);
}
.ThemeLETitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background-color: var(--background-color-1);
  padding-left: 16px;
}
.ThemeLEImgOuter {
  width: 100%;
  height: 70px;
  overflow: hidden;
  margin: 7px 0 0 0;
  opacity: 0.75;
}
.ThemeLEImgOuter:hover {
  box-shadow: var(--box-shadow-relaxed);
  cursor: pointer;
  opacity: 1;
}
.ThemeLEImage {
  width: 100%;
}
.LayoutListElement {
  min-height: 35px;
  padding: 0 20px !important;
  display: grid !important;
  grid-template-columns: 1fr 40px !important;
  gap: 16px !important;
}

@media screen and (max-width: 900px) {
  .DesktopHalfWidth {
    width: 100%;
  }
  .SettingsGroupRow {
    grid-template-columns: 1fr;
    padding-bottom: 16px;
  }
  .PageDeleteDetailsOuter {
    width: 100%;
    text-align: center;
  }
  .SmallSubstituteForFileInput {
    width: 100%;
    opacity: 0.9;
  }
  .LogoMask {
    margin: 0 auto;
  }
  .LogoMask > div {
    width: 100%;
  }
  .PageListElement {
    grid-template-columns: 1fr;
    align-items: center;
    padding: 3px 10px;
  }
  .PageContentOuter > li {
    grid-template-columns: 1fr;
    margin: 4px 0 0 0;
    transition: 250ms all;
  }
  .PageComponentListElementMask {
    padding: 8px;
    margin: 0;
    text-align: left;
    cursor: inherit;
  }
  .PageComponentListElementMask:hover {
    background-color: var(--background-color-0);
    color: var(--text-link);
  }
  .IconRemoveElement {
    padding: 8px;
    cursor: inherit;
  }
  .IconRemoveElement:hover {
    border: var(--border-discret);
    color: var(--text-link);
  }
  .TemplateColumnsOuter {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .LabelElementDetails {
    padding: 12px 0 4px 0;
  }
  .SelectLayoutSectionOuter {
    max-width: calc(100vw - 64px);
    margin: 0 auto;
  }
  .MobileButtonsSaveCancel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px;
    border-top: var(--border-discret);
    padding: 16px 0 0 0;
  }
}
