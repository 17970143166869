.Outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
}
.Outer > div {
  width: 178px;
}
.Outer > div:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 900px) {
  .Outer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  .Outer > div {
    width: 100%;
  }
  .Outer > div:last-child {
    margin: 0;
  }
}
