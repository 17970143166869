.DragHandle {
  min-height: 35px;
  height: 100%;
  width: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: move;
  background-image: url("../../assets/svg/cursor_move.svg");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
  transition: 75ms all;
}
.DragHandleOn {
  opacity: 1;
  background-image: url("../../assets/svg/cursor_move_on.svg");
}
.DragHandle:hover {
  opacity: 1;
}
.WrapperDragging {
  align-items: center;
  cursor: pointer;
  color: var(--text-link);
  padding: 12px 10px 12px 20px;
  background-color: white;
}

.WrapperStatic {
  width: 100%;
}
