.CustButtOuter {
  border: none;
  border-radius: var(--btn-bord-rad);
}

.CustButt {
  cursor: pointer;
  border: none;
  border-radius: var(--btn-bord-rad);
  height: 41px;
  outline: none !important;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.CustButt.FullWidth {
  width: 100%;
}
.CustButtSpan {
  cursor: pointer;
  border: none;
  border-radius: var(--btn-bord-rad);
  font-size: 16px;
  line-height: 41px;
  padding: 0 14px;
  text-align: center;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  display: block;
  color: var(--btn-txt-2);
  background: rgba(239, 239, 239, 1);
}

.CustButt:hover > .CustButtSpan {
  box-shadow: var(--btn-box-shad);
  background: rgba(239, 239, 239, 0.4);
}
.CustButt:disabled {
  cursor: not-allowed !important;
  background: var(--border-1) !important;
  box-shadow: var(--btn-box-shad) !important;
}
.CustButt:disabled > .CustButtSpan {
  color: var(--text-1) !important;
}

/* TWIN */
.Twin:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Twin:nth-child(1) > .CustButt {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Twin:nth-child(2) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.Twin:nth-child(2) > .CustButt {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:root {
  --btn-bord-rad: 4px;
  --btn-txt-00: #eaeaea;
  --btn-txt-0: #9a9a9a;
  --btn-txt-1: #565656;
  --btn-txt-2: #373737;
  --btn-txt-3: #212121;
  --btn-txt-4: #000000;

  /* gray */
  --btn-bord-col-gray: #ddd;
  --btn-bgr-gray: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
  --btn-bgr-gray-hover: linear-gradient(to bottom, #ebedf0, #dde0e4);
  --btn-bgr-gray-active: linear-gradient(to bottom, #f7f8fa, #dde0e4);
  /* blue */
  --text-btn-blue: rgb(0, 116, 212);
  --text-btn-blue-light: rgb(21 145 248);
  --btn-box-shad: inset 0 0 0 1px rgb(50 50 93 / 15%),
    0 2px 5px 0 rgb(50 50 93 / 10%), 0 2px 2px 0 rgb(0 0 0 / 7%);

  --btn-box-shad-hover: 0 6px 7px -2px rgba(50, 50, 50, 30%),
    rgba(0, 0, 0, 0.1) 0px -2px 6px -1px;

  /* type of linear gradient - maybe usefull */
  --type-of-background: linear-gradient(
    7deg,
    rgba(255, 204, 93, 0.85),
    rgba(244, 210, 41, 0.85),
    rgba(250, 199, 89, 0.85) 87.47%
  );
}

/* YELLOW */
.CustButtOuter.Yellow > .CustButt > .CustButtSpan {
  background-color: none;
  background: rgba(255, 204, 51, 1);
}
.CustButtOuter.Yellow > .CustButt:hover > .CustButtSpan {
  background: rgba(255, 219, 64, 1);
}
.CustButtOuter.Yellow > .CustButt:active > .CustButtSpan {
  background: rgba(255, 204, 51, 1);
}

/* GREEN */
.CustButtOuter.Green > .CustButt > .CustButtSpan {
  background-color: none;
  background: rgba(118, 221, 16, 1);
}
.CustButtOuter.Green > .CustButt:hover > .CustButtSpan {
  background: rgba(135, 237, 35, 1);
}
.CustButtOuter.Green > .CustButt:active > .CustButtSpan {
  background: rgba(118, 221, 16, 1);
}

/* BLUE */
.CustButtOuter.Blue > .CustButt > .CustButtSpan {
  background-color: none;
  background: rgba(0, 95, 244, 0.85);
  color: white;
}
.CustButtOuter.Blue > .CustButt:hover > .CustButtSpan {
  background: rgba(54, 145, 249, 0.85);
}
.CustButtOuter.Blue > .CustButt:active > .CustButtSpan {
  background: rgba(0, 95, 244, 0.85);
}

/* RED */
.CustButtOuter.Red > .CustButt > .CustButtSpan {
  background-color: none;
  background: rgba(241, 20, 12, 0.85);
  color: white;
}
.CustButtOuter.Red > .CustButt:hover > .CustButtSpan {
  background: rgba(255, 63, 43, 0.85);
}
.CustButtOuter.Red > .CustButt:active > .CustButtSpan {
  background: rgba(241, 20, 12, 0.85);
}

@media screen and (max-width: 900px) {
  .CustButt {
    height: 40px;
  }
  .CustButtSpan {
    font-size: 15px;
    line-height: 34px;
    margin: 0;
    padding: 3px 10px;
  }
  .Blue > .CustButt > .CustButtSpan {
    line-height: 34px;
  }
}
