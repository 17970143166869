.Outer {
  width: 100%;
  margin: 0 auto;
}
.HeaderInner {
  border-radius: var(--border-radius);
  background-color: var(--mockup-background);
  border: 2px solid var(--mockup-border);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 7px;
}
.Middle {
  margin: 10px 0;
  padding: 7px;
  border-radius: var(--border-radius);
  background-color: var(--mockup-background);
  border: 2px solid var(--mockup-border);
  text-align: center;
}
.ContentInner {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  background-color: white;
}
.LabelRoutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.Label {
  padding-bottom: 7px;
  padding-right: 21px;
}
.Label > span {
  font-size: var(--fs-small);
  font-weight: 500;
  color: var(--text-link);
  text-transform: uppercase;
  cursor: pointer;
}
.Label > span:hover {
  color: var(--text-link-hover);
}
.Column {
  border-radius: var(--border-radius);
  background-color: var(--mockup-background);
  border: 2px solid var(--mockup-border);
  padding: 7px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .Outer {
    width: calc(100vw - 32px);
    margin: 0 auto;
    overflow-x: auto;
  }
  .Inner {
    width: 700px;
  }
}
