.Outer {
  height: var(--website-header-height);
  width: 200px;
  border-radius: var(--border-radius);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto calc(var(--website-header-height) - 30px);
  cursor: pointer;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .Outer {
    width: calc(100vw - 32px);
  }
}
