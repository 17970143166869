.Inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.Inner:hover .Checkbox {
  border-color: var(--text-link-hover);
  background-color: var(--background-color-input-focus);
  border-color: var(--text-2);
  box-shadow: var(--btn-box-shadow-active);
}
.Inner:hover .Label {
  color: var(--text-link-hover);
}
.Checkbox {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--text-link);
  cursor: pointer;
  margin: 3px 0 0 0;
  background-color: white;
  transition: none;
}
.CheckboxDisabled {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: var(--border-radius);
  border: var(--border-discret);
  margin: 3px 0 0 0;
  background-color: white;
}
.Checked {
  background: url("../../../assets/svg/check.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 85% auto;
}
.Label {
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  width: calc(100% - 1.6rem);
}
.LabelDisabled {
  margin: 0 0 0 0.5rem;
  width: calc(100% - 1.6rem);
  color: var(--text-1);
}
.LabelError {
  color: var(--text-error);
}
.Error {
  color: var(--text-error);
  padding-top: 8px;
}
