:root {
  --color: #111;
}

.Loader,
.Marks,
.Dash,
.Loader:after,
.Marks:after,
.Dash:after,
.Loader:before,
.Marks:before,
.Dash:before {
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.Card {
  background: #fbfbfb;
  border-radius: var(--border-radius-big);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 17px;
  position: relative;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
}
.Card:hover {
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

@keyframes dash-spin {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(27deg);
  }
  24% {
    transform: rotate(-87deg);
  }
  60% {
    transform: rotate(-54deg);
  }
  62% {
    transform: rotate(-98deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes dash-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(0deg);
  }
  15% {
    -webkit-transform: rotate(27deg);
  }
  24% {
    -webkit-transform: rotate(-87deg);
  }
  60% {
    -webkit-transform: rotate(-54deg);
  }
  62% {
    -webkit-transform: rotate(-98deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes right-bounce {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(27deg);
  }
  24% {
    transform: rotate(-12deg);
  }
  47% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes right-bounce {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(0deg);
  }
  15% {
    -webkit-transform: rotate(27deg);
  }
  24% {
    -webkit-transform: rotate(-12deg);
  }
  47% {
    -webkit-transform: rotate(0deg);
  }
  65% {
    -webkit-transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes left-bounce {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  23% {
    transform: rotate(0deg);
  }
  24% {
    transform: rotate(-12deg);
  }
  50% {
    transform: rotate(0deg);
  }
  65% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes right-bounce {
  0% {
    -webkit-transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(0deg);
  }
  23% {
    -webkit-transform: rotate(0deg);
  }
  24% {
    -webkit-transform: rotate(-12deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  65% {
    -webkit-transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.Loader {
  box-sizing: content-box;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  width: 60px;
}
.Loader:before {
  box-sizing: content-box;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0;
}

.Marks {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.LeftMark {
  -webkit-animation: left-bounce 2.25s linear infinite;
  animation: left-bounce 2.25s linear infinite;
  height: 17px;
  margin: auto;
  position: relative;
  width: 17px;
}
.LeftMark:after,
.LeftMark:before {
  box-sizing: content-box;
  content: "";
  height: 12px;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 12px;
  right: -17px;
}

.LeftMark:before {
  border-bottom: 3px solid var(--color);
  border-left: 3px solid var(--color);
  left: 0;
}

.Dash {
  -webkit-animation: dash-spin 2.25s linear infinite;
  animation: dash-spin 2.25s linear infinite;
  height: 14px;
  left: 28px;
  position: absolute;
  top: 10px;
  width: 14px;
}
.Dash:before {
  border-right: 3px solid var(--color);
  box-sizing: content-box;
  content: "";
  height: 30px;
  left: calc(50% - 2px);
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(34deg);
  transform: translate(-50%, -50%) rotate(34deg);
  width: 0px;
}

.RightMark {
  -webkit-animation: right-bounce 2.25s linear infinite;
  animation: right-bounce 2.25s linear infinite;
  height: 17px;
  margin: auto;
  position: relative;
  width: 17px;
  right: -7px;
}
.RightMark:after,
.RightMark:before {
  box-sizing: content-box;
  content: "";
  height: 12px;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 12px;
  right: -17px;
}

.RightMark:after {
  border-right: 3px solid var(--color);
  border-top: 3px solid var(--color);
  right: 0;
}

@media screen and (max-width: 900px) {
  .Inner {
    padding: 54px 16px 21px 16px;
    min-width: calc(100vw - 64px);
    max-width: calc(100vw - 64px);
    width: calc(100vw - 64px);
    top: 33px;
    left: 32px;
  }
  .Title {
    font-size: var(--fs-2);
    font-weight: 500;
    padding: 4px 0 0 16px;
  }
}
